'use client'

export default function ErrorPage() {
    return (
        <div className="min-h-screen center flex flex-col justify-center items-center gap-5 px-5">
            <h1 className="text-4xl text-center">Pagina non trovata</h1>
            <h2 className="text-2xl text-center">Oops! Sembra che tu abbia digitato qualcosa di sbagliato… oppure hai scoperto un bug nel multiverso! 🕵️‍♂️🔍
            </h2>
        </div>
    )
    }